<template>
<div class="view">
    <el-input
        v-model="userList"
        :rows="10"
        type="textarea"
        :placeholder="lang['html.el-input.placeholder']"
        :disabled="isDisabled"
        v-loading="isLoading"
    />
    <el-tag type="info" style="margin: 10px">{{lang['html.el-tag.tips']}}</el-tag>
    <br>

    <el-popconfirm :title="lang['html-el-popconfirm.submit']" @confirm="submit">
        <template #reference>
            <el-button type="primary" :disabled="isDisabled">{{lang['html.button.submit']}}</el-button>
        </template>
    </el-popconfirm>


    <el-button type="warning" @click="userList = ''" :disabled="isDisabled">{{lang['html.button.reset']}}</el-button>
    <el-tag style="margin-left: 10px">{{lang['html.el-tag.userNum']}}: {{getUserNum}}</el-tag>
</div>
</template>

<script>
import {fromBase64} from "js-base64";

export default {
    name: "userRecovery",
    data(){
        return {
            lang:this.$lang.getLang('user','recovery'),
            isLoading:false,
            userList:'',//待回收的用户列表
            isDisabled:true,//是否禁止操作
        }
    },
    computed:{
        //计算用户数
        getUserNum(){
            let res = this.getUserListArr()
            if(res){
                return res.length;
            }
            return 0;
        },
    },
    methods:{
        //取过滤后的待操作用户数组
        getUserListArr(){
            if (this.userList == ''){
                return null;
            }
            let arr = this.userList.split('\n');
            let res = [];
            for (let arrKey in arr) {
                let str = arr[arrKey];
                if (str.length >= 1){
                    res.push(str);
                }
            }
            return res;
        },
        submit(){
            let arr = this.getUserListArr();
            if(!arr){
                this.$alert(this.lang['submit.error.user']);
                return;
            }
            this.isDisabled = this.isLoading = true;
            this.$message(this.lang['submit.tips.wait']);
            this.$api.post('User.Recovery/batchRecovery',{userList:arr.toString()}).then(res=>{
                this.isDisabled = this.isLoading  = false;
                let data = this.$helper.checkRes(res);
                if (!data){
                    //弹出异常信息
                    let code = this.$helper.getResErrorCode(res);
                    this.$helper.alertErrMsg(this.lang,'submit.res.code.',code,);
                    return;
                }
                //成功,跳转结果页面
                let result = res.data.result == null ? -1 : res.data.result;
                if (!result){
                    //取结果列表异常
                    this.$alert(this.lang['submit.error.result'],'warning',{type:"warning"});
                    return;
                }
                this.$message(this.lang['submit.jump.wait']);
                //跳转到结果页面
                this.$helper.jumpResultPage(
                    result,
                    this.lang,
                    'submit.res.state.',
                    this.lang['submit.title']);
            }).catch(err=>{
                this.isDisabled = this.isLoading = false;
                this.$helper.axiosCatch(err);
            })
        },

    },
    mounted() {
        //检查是否有传入用户列表参数
        let query = this.$route.params.userList;
        if (query){
            this.$notify({
                message:'Importing users...',
                type: 'info'
            });
            let str = fromBase64(query);
            let userList = str.replace(/,/g,"\n");
            // console.log('导入',userList)
            this.userList = userList;
        }
        this.isDisabled = false;
    }
}
</script>

<style scoped>
.view{
    width: 450px;
    margin:0 auto;
    margin-top: 20px;
}

</style>